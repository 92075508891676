export const getOperators = (level) => {
  switch (level) {
    case -2:
      return ["+", "-"];
    case -1:
      return ["+"];
    case 0:
      return ["*"];
    case 1:
      return ["+"];
    case 2:
      return ["+", "-"];
    case 3:
      return ["+", "-"];
    case 4:
      return ["+", "-"];
    case 5:
      return ["+", "-", "*"];
    case 6:
      return ["+", "-", "*"];
    case 7:
      return ["+", "-", "*", "/"];
    default:
      return ["+"];
  }
};
export const getMaxNumber = (level) => {
  switch (level) {
    case -2:
      return 5;
    case -1:
      return 5;
    case 0:
      return 10;
    case 1:
      return 5;
    case 2:
      return 7;
    case 3:
      return 10;
    case 4:
      return 12;
    case 5:
      return 12;
    case 6:
      return 15;
    case 7:
      return 15;
    default:
      return 5;
  }
};

const isInt = (n) => {
  return Number(n) === n && n % 1 === 0;
};

export default class LevelGenerator {
  constructor({ level, startId = 0, operations = {} }) {
    this.level = level;
    this.operations = operations;
    this.operators = getOperators(level);
    this.maxNumber = getMaxNumber(level);
    this.startId = startId;
  }

  randomNumber = () => {
    return Math.floor(Math.random() * this.maxNumber) + 1;
  };

  randomOperator = () => {
    const operatorIndex = Math.floor(Math.random() * this.operators.length);
    return this.operators[operatorIndex];
  };

  generateMatch = () => {
    let id = 0;
    let numbers = [];
    let operators = [];
    let result = 0;
    let randomResults = [];

    for (id; id < 10; id++) {
      operators = [this.randomOperator()];
      numbers = [this.randomNumber(), this.randomNumber()];

      if (this.level <= 2 || operators[0] === "/") {
        numbers = numbers.sort((n1, n2) => n2 - n1);

        if (operators[0] === "/") {
          numbers[1] = Math.floor(numbers[1] / 2) + 1;
        }
      }

      // eslint-disable-next-line no-eval
      result = eval(`${numbers[0]} ${operators[0]} ${numbers[1]}`);
      randomResults = this.generateRandomResults(result);

      this.operations[this.startId + id] = {
        numbers,
        operators,
        result: this.fixedNumber(result),
        submittedValue: null,
        randomResults,
        id: this.startId + id,
      };
    }

    return this.operations;
  };

  correctNumber = (operations) =>
    operations.reduce(
      (correctNumber, { submittedValue, result }) =>
        submittedValue === result ? correctNumber + 1 : correctNumber,
      0
    );

  generateRandomResults = (result) => {
    let results = [this.fixedNumber(result)];

    if (result < 0) {
      results.push(this.fixedNumber(result * -1));
    } else {
      results.push(this.fixedNumber(this.newNumberBigger(result)));
    }

    results.push(this.fixedNumber(this.newNumber(result)));
    results.push(this.fixedNumber(this.newCrazyNumber(result)));

    return this.shuffle(results);
  };

  fixedNumber = (n) => (isInt(n) ? n.toString() : n.toFixed(1));

  newNumberBigger = (result) => {
    const n = Math.floor(Math.random() * 2) + result;

    return n === result ? n + 1 : n;
  };

  newNumber = (result) => {
    const n = result - Math.floor(Math.random() * 2);

    return n === result ? n - 1 : n;
  };
  newCrazyNumber = (result) => {
    const n = result - Math.floor(Math.random() * result - 1);

    return n === result ? n + 1 : n;
  };

  shuffle = (array) => {
    var currentIndex = array.length,
      temporaryValue,
      randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  };
}
