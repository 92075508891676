import React from "react";
import CharacterWrapperCenter from "../../styled/CharacterWrapperCenter";
import styled from "styled-components";
import { getReadyMatch } from "../../redux/actions/match";
import { GiPencil } from "react-icons/gi";
import { BsBook } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { useTimer } from "react-timer-hook";
import { useTranslation } from "react-i18next";

const PreMatch = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const expiryTimestamp = new Date();
  expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + 5);

  const { seconds } = useTimer({
    expiryTimestamp,
    onExpire: () => {
      dispatch(getReadyMatch());
    },
  });

  return (
    <StyledCharacterWrapperCenter>
      <Big>
        <BsBook />
        <GiPencil />
      </Big>
      <p>{t("Get ready your pen and paper!")}</p>
      <p>{t("The match starts in")}:</p>
      <Big>{seconds}</Big>
    </StyledCharacterWrapperCenter>
  );
};

export default PreMatch;

const StyledCharacterWrapperCenter = styled(CharacterWrapperCenter)`
  padding-top: 50px;
  padding-bottom: 50px;
`;

const Big = styled.div`
  font-size: calc(30px + 2vmin);
`;
