import {
  START_MATCH,
  END_MATCH,
  SET_LEVEL,
  SET_INFO,
  PRACTICE,
  MATCH_PREPARATION,
} from "../actions/match";
import * as MatchStatus from "../../helper/MatchStatus";

const defaultSate = {
  status: MatchStatus.WAITING,
  level: 1,
  info: null,
};

const normalizeLevel = (level) => {
  if (level <= -2) {
    return -2; // min level
  } else if (level > 7) {
    return 7; // max level
  } else {
    return level;
  }
};

const reducer = (state = defaultSate, action) => {
  const { payload } = action;

  switch (action.type) {
    case START_MATCH:
      return { ...state, status: MatchStatus.START };

    case MATCH_PREPARATION:
      return { ...state, status: MatchStatus.PREPARING };

    case PRACTICE:
      return { ...state, status: MatchStatus.PRACTICE };

    case END_MATCH:
      return { ...state, status: MatchStatus.WAITING };

    case SET_LEVEL:
      return { ...state, level: normalizeLevel(state.level + payload.level) };

    case SET_INFO:
      return { ...state, info: payload.info };

    default:
      return state;
  }
};

export default reducer;
