import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CharacterWrapper from "../../styled/CharacterWrapper";
import Hp from "../hp";
import Icon from "./icon";
import Status from "../../styled/Status";
import { AiFillStar } from "react-icons/ai";
import { GiTargetLaser } from "react-icons/gi";
import { useSelector, useDispatch } from "react-redux";
import { endMatch, setInfo } from "../../redux/actions/match";
import { hitPlayer, addMoney, healPlayer } from "../../redux/actions/player";
import { useTimer } from "react-timer-hook";
import { useTranslation } from "react-i18next";

const getLevelSeconds = (enemyLevel, matchLevel) => {
  if (matchLevel < 0) return 20;

  return 15 - enemyLevel;
};

const Enemy = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [attacking, setAttacking] = useState(false);
  const [matchLevel] = useSelector(({ match }) => [match.level]);

  const { id, name, hp, level, loot, maxHp } = useSelector(
    (state) => state.enemy.currentEnemy
  );
  const expiryTimestamp = new Date();

  expiryTimestamp.setSeconds(
    expiryTimestamp.getSeconds() + getLevelSeconds(level, matchLevel)
  );

  const { seconds, restart } = useTimer({
    expiryTimestamp,
    onExpire: () => {
      setAttacking(true);
      dispatch(hitPlayer(1));
    },
  });

  useEffect(() => {
    if (hp <= 0) {
      dispatch(
        setInfo({ msg: t("You Won!"), gold: `+${loot.money + matchLevel}` })
      );
      dispatch(endMatch());
      dispatch(healPlayer(2));
      dispatch(addMoney(loot.money + matchLevel));
    }
  }, [dispatch, hp, loot.money, matchLevel, t]);

  useEffect(() => {
    if (seconds <= 0) {
      setTimeout(() => {
        restart(expiryTimestamp);
        setAttacking(false);
      }, 70);
    }
  }, [expiryTimestamp, restart, seconds]);

  return (
    <CharacterWrapper>
      <Status>
        <Icon id={id} /> {t(name)}
      </Status>
      <Wrapper>
        <Hp hp={hp} maxHp={maxHp} />
        <Status>
          <AiFillStar /> {level}
        </Status>
        <Status style={{ color: "red" }}>
          <GiTargetLaser /> {seconds}
        </Status>
      </Wrapper>
      {attacking && <AttackLaser />}
    </CharacterWrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
`;

const AttackLaser = styled.div`
  position: fixed;
  border-left: 6px solid red;
  height: 70vh;
  top: 10%;
  transform: rotate(14deg);
`;

export default Enemy;
